<template>
  <div class="card">

    <div class="card-header bg-transparent header-elements-sm-inline py-sm-0">

      <h5 class="card-title py-sm-3"><span class="icon-list"></span>&nbsp;&nbsp;Knitting QC List</h5>

      <div class="header-elements">
        <form action="#">
          <div class="form-group-feedback form-group-feedback-right">
            <input  id="txtsearch" type="search" class="form-control wmin-sm-200" placeholder="Search..." autocomplete="off">
            <div class="form-control-feedback">
              <i class="icon-search4 font-size-base text-muted"></i>
            </div>
          </div>
        </form>

      </div>
    </div>


    <div class="card-body" style="padding: 0px;">
      <div class="table-responsive">
      <table id="mytable"
             class="table  table-hover  table-bordered table-condensed table-columned"
             data-search="false"
             data-pagination="true"
             data-show-refresh="false"
             data-show-columns="false"
             data-page-list="[10, 25, 50, 100, ALL]"
             data-show-footer="false"
             data-toggle="context"
             data-target=".context-table">
        <thead>
        <tr>
          <th data-field="id" data-class="d-none">id</th>
          <th data-formatter="runningFormatter" data-width="75" >S.No</th>
          <th data-field="doc_no" data-width="100" data-sortable="true">Doc No</th>
          <th data-field="doc_date" data-width="110" data-formatter="dateFormatter"  data-sortable="true">Date</th>
          <th data-field="shift.name" data-sortable="true">Shift</th>
          <th data-field="employee.name" data-sortable="true">Operator</th>
          <th data-field="remarks" data-sortable="true">Remarks</th>
        </tr>
        </thead>
        <tbody>
        </tbody>
        <tfoot>
        </tfoot>
      </table>
    </div>
    </div>

    <!-- Context Menu -->
    <div id="context-menu" class="context-table">
      <div class="dropdown-menu">
        <a href="#" class="dropdown-item" @click="loadData"><i class="icon-reset" style="color: blue"></i>Refresh</a>
        <a href="#" class="dropdown-item" ><i class="icon-pencil7" style="color: #2d7330"></i>Modify</a>
        <div class="dropdown-divider"></div>
        <a href="#" class="dropdown-item" ><i class="icon-blocked" style="color: orangered"></i>Remove</a>
<!--        <div class="dropdown-divider"></div>-->
<!--        <a href="#" class="dropdown-item" ><i class="icon-filter4"></i>Send To Stentering</a>-->
      </div>
    </div>
    <!-- End of Context Menu -->

    <FocusTrap>
      <div class="modal" id="knitting_qc_window" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog  modal-full modal-dialog-centered modal-dialog-scrollable" >
        <div class="modal-content" >

          <!-- Modal body -->
          <div class="modal-body" style="padding: 0px">
            <QcForm v-if="voucher.id > 1" v-bind:myqc="voucher" v-on:knitting_qc_window_closed="closeModal" v-on:knitting_qc_saved="loadData" ></QcForm>
          </div>

        </div>
      </div>
    </div>
    </FocusTrap>

    <!-- Clickable menu -->
    <ul class="fab-menu fab-menu-fixed fab-menu-bottom-right is_stuck" data-fab-toggle="hover" z-index="0">
      <li>
        <a class="fab-menu-btn btn bg-primary-600 btn-float rounded-round btn-icon" @click="showFilterWindow">
          <i class="fab-icon-open icon-filter3"></i>
          <i class="fab-icon-close icon-filter4"></i>
        </a>
      </li>
    </ul>
    <!-- /clickable menu -->


    <FocusTrap>
      <vmodal name="filter-window" transition="nice-modal-fade" :delay="100" :resizable="true" :width="575" height="250" @opened="afterfilterwindow_visible">
        <form>
          <div class="card">
            <div class="card-header header-elements-inline bg-primary" style="padding: 10px;">
              <h3 class="card-title">Filter</h3>
              <div class="header-elements">
                <div class="list-icons">
                  <!--<a class="list-icons-item" data-action="remove" @click="closeRequest"></a>-->
                </div>
              </div>
            </div>

            <div class="card-body">

              <p/>

              <div class="form-group row">
                <label class="col-md-3 col-form-label">From:</label>
                <div class="col-md-3">
                  <input id= "txtfrom" ref= "txtfrom" type="date" class="form-control"  v-model="from_date" :max="upto_date" autofocus />
                </div>

                <label class="col-md-3 col-form-label">Upto</label>
                <div class="col-md-3">
                  <input type="date" class="form-control"  v-model="upto_date" :min="from_date" />
                </div>
              </div>


              <div class="form-group row">
                <label class="col-md-3 col-form-label">Machine:</label>
                <div class="col-md-9">
                  <select id="cmbMachine" class="form-control" v-model="machine_id" >
                    <option value="0" selected>All</option>
                    <option v-for="camp in machines" v-bind:value="camp.id">
                      {{ camp.name }}
                    </option>
                  </select>
                </div>
              </div>

<!--              <div class="form-group row">-->
<!--                <label class="col-md-3 col-form-label">Quality:</label>-->
<!--                <div class="col-md-9">-->
<!--                  <select id="cmbMachine" class="form-control" v-model="quality_id" >-->
<!--                    <option value="0" selected>All</option>-->
<!--                    <option v-for="camp in qualities" v-bind:value="camp.id">-->
<!--                      {{ camp.name }}-->
<!--                    </option>-->
<!--                  </select>-->
<!--                </div>-->
<!--              </div>-->

<!--              <div class="form-group row">-->
<!--                <label class="col-md-3 col-form-label">Color:</label>-->
<!--                <div class="col-md-9">-->
<!--                  <select id="cmbMachine" class="form-control" v-model="color_id" >-->
<!--                    <option value="0" selected>All</option>-->
<!--                    <option v-for="camp in colors" v-bind:value="camp.id">-->
<!--                      {{ camp.name }}-->
<!--                    </option>-->
<!--                  </select>-->
<!--                </div>-->
<!--              </div>-->

              <div class="text-right">
                <button type="button" class="btn btn-outline alpha-success text-success-800 border-success-600 legitRipple" @click="loadData" >Show Data<i class="icon-database-refresh ml-2"></i></button>
              </div>

            </div>
          </div>
        </form>
      </vmodal>
    </FocusTrap>


  </div>
</template>

<script>
  import QcForm from '@/views/fms/knitting/KnittingQcForm.vue'
  import FabButton from '@/components/core/FabButton.vue'
  import moment from 'moment-timezone'
  import {userService} from '@/store/auth-header.js'
  import { store } from '@/store/store.js'
  export default {
    name: 'KnittingQcView',
    store,
    components: {
      QcForm,
      FabButton,
      moment
    },
    data () {
      return {
        voucher: JSON.parse('{"id":0,"status":"Active","finyear":1,"doc_no":1,"doc_date":"2000-01-01","cmp_id":1,"shift_id":1,"mac_id":1,"emp_id":1,"needle_damages":0,"hole_damages":0,"other_damages":0,"weight":0,"trn_id":1,"remarks":"","shift":{"id":1,"status":"Active","name":""},"machine":{"id":1,"type":0,"name":"","status":"Active"},"employee":{"id":1,"code":"","status":"Active","name":"","design_id":1,"extrusion":false,"stentering":false,"warping":false,"designation":{"id":1,"status":"Active","name":"","min_wage":0}},"list":[]}'),
        mytable: {},
        from_date: moment().add(-7, 'days').format('YYYY-MM-DD'),
        upto_date: moment().format('YYYY-MM-DD'),
        machine_id : 0,
        quality_id : 0,
        color_id : 0,
        machines : [],
        colors: [],
        qualities : [],
      }
    },
    created () {

    },
    mounted () {
      const self = this;
      this.$data.mytable = $('#mytable');
      this.$data.mytable.bootstrapTable();

      $('[data-toggle="popover"]').popover();
      $('#mytable').contextmenu({
        target: '#context-menu',
        scopes: 'tbody > tr',
        onItem: function (row, e) {

          let id = $(row.children('*')[0]).text();

          if ($(e.target).text() === "Refresh") {
            self.loadData();
          }else if($(e.target).text() === 'Modify'){
            self.modifyDocument(id);
          }else if($(e.target).text() == 'Remove'){
            self.removeDocument(id);
          }else if($(e.target).text() == 'Send To QC'){
            self.sendToQc(id);
          }


        }
      });

      $('#txtsearch').keyup(function () {
        let val = $.trim($(this).val()).replace(/ +/g, ' ').toLowerCase();
        $('#mytable>tbody>tr').show().filter(function () {
          var text = $(this).text().replace(/\s+/g, ' ').toLowerCase();
          return !~text.indexOf(val);
        }).hide();
      });

      $('[data-action=remove]').click(function (e) {
        $('#knitting_qc_window').modal('hide');
      });

      self.loadFilterData();
      self.$modal.show('filter-window');
      // self.loadData();
    },
    methods: {
      afterfilterwindow_visible(){
        $('#txtfrom').focus();
      },
      showFilterWindow(){
        this.$modal.show('filter-window');
      },
      closeModal() {
        this.$modal.hide('knitting_qc_window');
        $('#knitting_qc_window').modal('hide');
      },
      beforeOpen(){
      },
      beforeClose(){
        this.voucher = JSON.parse('{"id":0,"status":"Active","finyear":1,"doc_no":1,"doc_date":"2000-01-01","cmp_id":1,"shift_id":1,"mac_id":1,"emp_id":1,"needle_damages":0,"hole_damages":0,"other_damages":0,"weight":0,"trn_id":1,"remarks":"","shift":{"id":1,"status":"Active","name":""},"machine":{"id":1,"type":0,"name":"","status":"Active"},"employee":{"id":1,"code":"","status":"Active","name":"","design_id":1,"extrusion":false,"stentering":false,"warping":false,"designation":{"id":1,"status":"Active","name":"","min_wage":0}},"list":[]}');
      },
      showModal () {
        $('#knitting_qc_window').modal('show');
      },
      clear(){
        this.voucher = JSON.parse('{"id":0,"status":"Active","finyear":1,"doc_no":1,"doc_date":"2000-01-01","cmp_id":1,"shift_id":1,"mac_id":1,"emp_id":1,"needle_damages":0,"hole_damages":0,"other_damages":0,"weight":0,"trn_id":1,"remarks":"","shift":{"id":1,"status":"Active","name":""},"machine":{"id":1,"type":0,"name":"","status":"Active"},"employee":{"id":1,"code":"","status":"Active","name":"","design_id":1,"extrusion":false,"stentering":false,"warping":false,"designation":{"id":1,"status":"Active","name":"","min_wage":0}},"list":[]}');
      },
      modifyDocument (id) {
        const self = this;

        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };


        self.$data.voucher = JSON.parse('{"id":0,"status":"Active","finyear":1,"doc_no":1,"doc_date":"2000-01-01","cmp_id":1,"shift_id":1,"mac_id":1,"emp_id":1,"needle_damages":0,"hole_damages":0,"other_damages":0,"weight":0,"trn_id":1,"remarks":"","shift":{"id":1,"status":"Active","name":""},"machine":{"id":1,"type":0,"name":"","status":"Active"},"employee":{"id":1,"code":"","status":"Active","name":"","design_id":1,"extrusion":false,"stentering":false,"warping":false,"designation":{"id":1,"status":"Active","name":"","min_wage":0}},"list":[]}');
        fetch(`${process.env.VUE_APP_ROOT_API}api/knitting/qc/${id}`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if  (resp.ok ) {
            if(!_.isNull(resp.data)){
              self.$data.voucher = resp.data;
              self.$data.voucher.doc_date = moment(self.$data.voucher.doc_date).format('YYYY-MM-DD');

              // console.log(JSON.stringify(self.$data.voucher));

              $('#knitting_qc_window').modal('show');
            }
          } else {
            swal ( { title: "Oops!" ,  text: resp.msg, type:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text:  err.toString() , type:  "error"} );
        });
      },
      removeDocument (id) {
        const self = this;

        self.$data.voucher.id = id;

        const requestOptions = {
          method: 'DELETE',
          mode:'cors',
          headers: userService.authHeader(),
          body: JSON.stringify(self.$data.voucher)
        };

        swal({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          type: 'warning',
          timer: 3000,
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete this!'
        }).then((result) => {
          if (result.value) {
            fetch(`${process.env.VUE_APP_ROOT_API}api/knitting/qc`,requestOptions).then(userService.handleResponse).then(function (resp) {
              self.$modal.hide('knitting_qc_window')
              self.loadData();
              swal(
                'Deleted!',
                'Your request has been processed',
                'success'
              );
            }).catch(function (err) {
              swal({ title: "Oops", text: err.toString(), type: "error" });
            });
          }
        });

      },
      loadData () {
        const self = this;

        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };

        $('#txtsearch').val('');
        self.$data.mytable.bootstrapTable('load',[]);
        self.$modal.hide('filter-window');
        this.$modal.hide('knitting_qc_window');
        $('#knitting_qc_window').modal('hide');
        self.$data.mytable.bootstrapTable('showLoading');

        let filters = `from=${self.from_date}&upto=${self.upto_date}&machine_id=${self.machine_id}&quality_id=${self.quality_id}&color_id=${self.color_id}`;

        // alert(`${process.env.VUE_APP_ROOT_API}api/knitting/qcs?${filters}`);
        fetch(`${process.env.VUE_APP_ROOT_API}api/knitting/qcs?${filters}`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if(resp.ok) {
            if( !_.isNull(resp.data)){
              self.$data.mytable.bootstrapTable('load', resp.data);
            }
          } else {
            swal ( { title: "Oops!" ,  text: resp.msg, type:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
        }).finally(function () {
          self.$data.mytable.bootstrapTable('hideLoading');
          $(self.$data.mytable).unblock();
        });
      },

      loadFilterData(){
        const self = this;

        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };

        self.$data.machines = [];

        fetch(`${process.env.VUE_APP_ROOT_API}api/machines/type/3`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if(resp.ok) {
            if(_.isArray(resp.data)){
              self.$data.machines =  resp.data;
            }
          } else {
            swal ( { title: "Oops!" ,  text: resp.msg, type:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
        });


        //Load Qualities
        self.$data.qualities = [];
        fetch(`${process.env.VUE_APP_ROOT_API}api/qualities/after/1`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if(resp.ok) {
            if(resp.data != null){
              self.$data.qualities = resp.data;
            }
          } else {
            swal ( { title: "Oops!" ,  text: resp.msg, type:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
        });


        //Load Colors
        self.$data.colors = [];
        fetch(`${process.env.VUE_APP_ROOT_API}api/colorcharts/after/1`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if(resp.ok) {
            if(resp.data != null){
              self.$data.colors = resp.data;
            }
          } else {
            swal ( { title: "Oops!" ,  text: resp.msg, type:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
        });



      },
    }
  }
</script>

<style scoped>
  .modal-full {
    min-width: 100%;
    margin: 0;
  }

  .modal-full .modal-content {
    min-height: 100vh;
  }

  input:focus {
    background: #feff00;
  }

  textarea:focus {
    background: #feff00;
  }

  select:focus {
    background: #feff00;
  }

  button:focus {
    background: #feff00;
    color: #0a0a0a!important;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
</style>
